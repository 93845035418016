.table {
    $td-padding: 10px;

    border-collapse: collapse;
    width: 100%;

    thead {
        background-color: #edf0f4;
        border-bottom: 1px solid #8f9298;

        tr {
            height: 30px;

            td,
            tr {
                padding-left: $td-padding;
                padding-right: $td-padding;
            }
        }
    }

    tbody {
        tr {
            height: 40px;
            border-bottom: 1px solid #8f9298;
            font-size: 16px;

            td {
                padding-left: $td-padding;
                padding-right: $td-padding;
            }
        }
    }
}
