%checkboxStyle {
    max-height: 100%;
    height: 30px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); // Adjust for centering
    width: 70px;
    border-radius: 30px;
    border: 1px solid gray;
}

.container {
    position: relative;

    > input {
        opacity: 0;
        @extend %checkboxStyle;
    }

    > label {
        cursor: pointer;
        //display: block;
        font-size: 14px;
        line-height: 30px; // Center vertically
        width: 70px;
        height: 30px;
    }

    input + label {
        &::before {
            content: "";
            @extend %checkboxStyle;
        }

        /* Checkmark */
        &::after {
            content: "";
            border: 1px solid white;
            border-radius: 50%;
            height: 26px;
            opacity: 1;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 26px;
            background-color: white;
            left: 2px;
            // transition: opacity 0.1s ease-in-out;
        }
    }

    input:checked + label {
        &::after {
            opacity: 1;
            right: 2px;
            left: auto;
        }

        &::before {
            border: 1px solid grey;
            border-radius: 30px;
            background-color: green;
        }
    }
}
