@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";

/*
// REQUIRED
@import "~bootstrap/scss/functions";

// overridden bootstrap variables go here
@import "variables";

// REQUIRED
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

//OPTIONAL
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack

*/

// ======================= CUSTOM ZBOX STYLES ===================

.card {
    margin-bottom: 1.5rem;
}

.table td {
    vertical-align: middle;
}

.btn-white {
    @include button-variant(
        $white,
        $gray-400,
        $black,
        $gray-200,
        $gray-500,
        $black,
        $gray-400,
        $gray-600,
        $black,
        $gray-100,
        $gray-300,
        $gray-700
    );
}

.invoice-tbl {
    width: 15%;
}

//provides a nice hover effect for inactive tabs; does not account for disabled tabs
.nav {
    .nav-link {
        &:hover:not(.active) {
            background-color: $gray-200;
        }
    }
}
//causes issues with buttons in every instance that has buttons side by side
// .btn ~ .btn {
//     margin-left: 0.5em;
// }

//link variants now look just like anchor elements
.btn-link {
    padding: 0;
    border: 0;
    -webkit-user-select: text; /* Chrome all / Safari all */
    -moz-user-select: text; /* Firefox all */
    -ms-user-select: text; /* IE 10+ */
    user-select: text;
}

/*
.d-grid .btn {
    margin-left: 0px;
}
*/

/*
.card-body.isTable {
    padding: 0.25rem 0.5rem;
    > table {
        > tbody > tr:last-child {
            border-bottom: 0;
            > td {
                border-bottom: 0;
            }
        }
    }
}
*/

label.is-invalid {
    color: $red;
}

label.is-valid {
    color: $green;
}

label {
    font-weight: bold;
}

.modal-header .btn-close {
    padding: 1rem 1rem;
}

.navbar .navbar-nav > * {
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.hide {
    display: none !important;
}

@include media-sm {
    .hide-sm {
        display: none !important;
    }
}
@include media-md {
    .hide-md {
        display: none !important;
    }
}
@include media-lg {
    .hide-lg {
        display: none !important;
    }
}
@include media-xl {
    .hide-xl {
        display: none !important;
    }
}
@include media-xxl {
    .hide-xxl {
        display: none !important;
    }
}
@include media-not-sm {
    .show-sm {
        display: none !important;
    }
}
@include media-not-md {
    .show-md {
        display: none !important;
    }
}
@include media-not-lg {
    .show-lg {
        display: none !important;
    }
}
@include media-not-xl {
    .show-xl {
        display: none !important;
    }
}
@include media-not-xxl {
    .show-xxl {
        display: none !important;
    }
}

/*
label {
    margin-bottom: 0px;
    line-height: 20px;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: $gray-6;
}

pre {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    background-color: $gray-5;
    overflow: auto;
    border: none;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    padding: 1em 1.5em;
}

code {
    background-color: $gray-5;
}

span.loading {
    color: $gray-4;
    background-color: $gray-4;
}

a:hover {
    text-decoration: none;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.flex-5 {
    flex: 5;
}

.flex-6 {
    flex: 6;
}

.flex-7 {
    flex: 7;
}

.flex-8 {
    flex: 8;
}

.flex-9 {
    flex: 9;
}

.flex-10 {
    flex: 10;
}

.h1b {
    font-weight: bold;
    font-size: 24px;
}

.h1r {
    font-weight: normal;
    font-size: 24px;
}

.h2b {
    font-weight: bold;
    font-size: 22px;
}

.h3b {
    font-weight: bold;
    font-size: 20px;
}

.h4b {
    font-weight: bold;
    font-size: 18px;
}

.h4m {
    font-weight: 500;
    font-size: 18px;
}

.h4r {
    font-weight: 400;
    font-size: 18px;
}

.h5b {
    font-weight: bold;
    font-size: 16px;
}

.h5m {
    font-weight: 500;
    font-size: 16px;
}

.h5r {
    font-weight: normal;
    font-size: 16px;
}

.b1b {
    font-weight: bold;
    font-size: 14px;
}

.b1m {
    font-weight: 500;
    font-size: 14px;
}

.b1r {
    font-weight: normal;
    font-size: 14px;
}

.b2b {
    font-weight: bold;
    font-size: 12px;
}

.b2m {
    font-weight: 500;
    font-size: 12px;
}

.b2r {
    font-weight: normal;
    font-size: 12px;
}

// All
.m-0 {
    margin: 0;
}

.m-5 {
    margin: 5px;
}

.m-10 {
    margin: 10px;
}

.m-15 {
    margin: 15px;
}

.m-20 {
    margin: 20px;
}

.m-25 {
    margin: 25px;
}

.m-30 {
    margin: 30px;
}

// left
.ml-0 {
    margin-left: 0;
}

.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-25 {
    margin-left: 25px;
}

.ml-30 {
    margin-left: 30px;
}

// bottom
.mb-0 {
    margin-bottom: 0;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

// right
.mr-0 {
    margin-right: 0;
}

.mr-5 {
    margin-right: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-25 {
    margin-right: 25px;
}

.mr-30 {
    margin-right: 30px;
}

// top
.mt-0 {
    margin-top: 0;
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

//all
.p-0 {
    padding: 0px;
}

.p-5 {
    padding: 5px;
}

.p-10 {
    padding: 10px;
}

.p-15 {
    padding: 15px;
}

.p-20 {
    padding: 20px;
}

.p-25 {
    padding: 25px;
}

.p-30 {
    padding: 30px;
}

//left
.pl-0 {
    padding-left: 0px;
}

.pl-5 {
    padding-left: 5px;
}

.pl-10 {
    padding-left: 10px;
}

.pl-15 {
    padding-left: 15px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-25 {
    padding-left: 25px;
}

.pl-30 {
    padding-left: 30px;
}

//right
.pr-0 {
    padding-right: 0px;
}

.pr-5 {
    padding-right: 5px;
}

.pr-10 {
    padding-right: 10px;
}

.pr-15 {
    padding-right: 15px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-25 {
    padding-right: 25px;
}

.pr-30 {
    padding-right: 30px;
}

//top
.pt-0 {
    padding-top: 0px;
}

.pt-5 {
    padding-top: 5px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-25 {
    padding-top: 25px;
}

.pt-30 {
    padding-top: 30px;
}

//bottom
.pb-0 {
    padding-bottom: 0px;
}

.pb-5 {
    padding-bottom: 5px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-25 {
    padding-bottom: 25px;
}

.pb-30 {
    padding-bottom: 30px;
}
*/
