.mainContainer {
    padding: 5px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.checkboxesContainer {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
}

.labelCheckbox {
    $border-color: #e3e3e3;
    $form-control-disabled: #959495;
    $check-color: white;
    $background-color-for-checked: rgb(50, 50, 255);

    border: 1px solid $border-color;
    border-radius: 50px;
    height: 30px;

    display: grid;
    place-content: center;
    padding: 5px 7px 5px 5px;

    font-family: system-ui, sans-serif;
    font-size: 1.1rem;
    font-weight: 550;
    line-height: 1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;

    .labelText {
        color: black;
    }

    input[disabled] {
        + .labelText {
            color: $form-control-disabled;
        }
    }

    input[type="checkbox"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        appearance: none;
        /* For iOS < 15 to remove gradient background */
        background-color: $check-color;
        /* Not removed via appearance */
        margin: 0;

        font: inherit;
        color: currentColor;
        width: 1.15em;
        height: 1.15em;
        border: 0.15em solid $border-color;
        border-radius: 50%;

        display: grid;
        place-content: center;

        &::before {
            content: "";
            width: 0.65em;
            height: 0.65em;
            transform: scale(0);
            //transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $check-color;
            /* Windows High Contrast Mode */
            background-color: CanvasText;
            //transform-origin: bottom left;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        }

        &:checked {
            background-color: $background-color-for-checked;
            border: 0.15em solid $background-color-for-checked;

            &:disabled {
                background-color: $form-control-disabled;
                border: 0.15em solid $form-control-disabled;
            }
        }

        &:checked::before {
            transform: scale(1);
        }

        // &:focus {
        //     outline: max(2px, 0.15em) solid currentColor;
        //     outline-offset: max(2px, 0.15em);
        // }

        &:disabled {
            // box-shadow: inset 1em 1em $form-control-disabled;
            color: $form-control-disabled;
            cursor: not-allowed;
            background-color: white;
            border: 0.15em solid $form-control-disabled;

            &::before {
                /* Updated box-shadow property for disabled checkbox */
                box-shadow: inset 1em 1em $check-color;
            }
        }
    }
}

.stationContainer {
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    > h4 {
        font-weight: 550;
    }

    .stationLineContainer {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .stationLine {
            > span {
                font-weight: 550;
            }

            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .assignToStationButton {
        margin-top: 10px;
        background-color: rgb(221, 221, 221);
        border: 2px solid white;
        border-radius: 5px;
        height: 35px;
        font-weight: 550;
    }

    .removeFromStationButton {
        margin-top: 5px;
        background-color: rgb(220, 75, 75);
        border: 2px solid white;
        border-radius: 5px;
        height: 35px;
        font-weight: 550;
        color: white;
    }
}

.locationsContainer {
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    > h4 {
        font-weight: 550;
    }

    .locationsTable {
        width: 100%;
        table-layout: fixed;

        thead {
            th,
            td {
                &:not(:first-child) {
                    text-align: center;
                }

                font-weight: 550;
            }
        }

        tbody {
            tr {
                td {
                    padding: 5px 0;

                    &:not(:first-child) {
                        text-align: center;
                    }

                    &:last-child {
                        text-align: end;
                    }
                }
            }
        }
    }
}
