//temp
.container {
    display: flex;

    .link {
        color: #212529;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .crumb {
        margin-right: 10px;
    }
}
