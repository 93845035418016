.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    z-index: 2;
    overscroll-behavior: contain;
}

.container {
    margin: 15px;
    padding: 15px;
    border-radius: 4px;
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.closeButton {
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    font-size: 28px;
    color: black;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    line-height: 1;
    height: 35px;
}

.title {
    font-weight: bold;
    font-size: 24px;
}

.filtersScrollContainer {
    overflow-y: auto;
    flex-grow: 1;
}

.filtersDirectionContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.filterTitle {
    font-weight: 550;
    font-size: 16px;
    position: relative;
}

%filterContainer {
    background-color: rgb(240, 240, 240);
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    border-radius: 4px;
}

.filterContainerToggle {
    @extend %filterContainer;
    align-items: center;
}

.filterContainerCheckboxes {
    @extend %filterContainer;
    flex-direction: column;
    gap: 10px;

    .checkboxesContainer {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        margin-bottom: 5px;
    }
}

.filterContainerInput {
    @extend %filterContainer;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px; // This is an override. %filterContainer contains the normal padding value.
}

.applyFiltersButton {
    background-color: #2f2f2f;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
}
