.container {
    height: 90px;
    width: 100%;
    background-color: transparent;
    color: black;
    z-index: 1000;
    position: fixed;
    bottom: -90px;
    padding: 10px;
    display: flex;
    justify-content: center;
}

.actionBar {
    background-color: rgba(100, 100, 100, 0.3);
    backdrop-filter: blur(20px);
    width: 100%;
    max-width: 550px;
    height: 100%;
    border-radius: 5px;
    padding: 10px;
}

.slideIn {
    animation: movein 0.2s ease forwards;
}

.slideOut {
    animation: moveout 0.2s ease forwards;
}

@keyframes movein {
    from {
        bottom: -90px;
    }

    to {
        bottom: 0;
    }
}

@keyframes moveout {
    from {
        bottom: 0;
    }

    to {
        bottom: -90px;
    }
}

.defaultContentContainer {
    font-weight: 500;
    color: blue;
    font-size: 18px;
}
