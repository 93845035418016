.inputTypeNumber {
    max-width: 40px;
    border-radius: 4px;
    border: 1px solid rgb(40, 40, 40);
    text-align: center;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    -moz-appearance: textfield;
}
