.loader {
    border-radius: 50%;
    height: 100%;
    max-width: 120px;
    max-height: 120px;
    animation: spin 2s linear infinite;
    aspect-ratio: 1/1;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
