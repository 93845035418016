.imageSetContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.image {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b9bdce;
    width: 100%;
    max-width: 172.5px;
    height: 172.5px;
    border-radius: 2px;
    position: relative;

    > img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        overflow: hidden;
    }

    //hides the broken img icon if img src is null/undefined
    > img[alt]:after {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        font-weight: 500;
        content: attr(alt);
        overflow-wrap: anywhere;
    }
}
