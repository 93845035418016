.container {
    margin: 400px auto;
    font-size: 20px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: load5 1.1s infinite ease;
    transform: translateZ(0);
    box-shadow: 0em -2.6em 0em 0em #ffea00, 1.8em -1.8em 0 0em rgba(254, 241, 96, 0.2), 2.5em 0em 0 0em rgba(254, 241, 96, 0.2),
        1.75em 1.75em 0 0em rgba(254, 241, 96, 0.2), 0em 2.5em 0 0em rgba(254, 241, 96, 0.2), -1.8em 1.8em 0 0em rgba(254, 241, 96, 0.2),
        -2.6em 0em 0 0em rgba(254, 241, 96, 0.5), -1.8em -1.8em 0 0em rgba(254, 241, 96, 0.7);

    &.hosted-field {
        font-size: 4px;
        width: 6px;
        height: 6px;
        margin: 0px auto;
        transform: translate(-50%, -50%);
        position: absolute;
        left: 50%;
        top: 50%;
    }

    &.mobile-sm {
        font-size: 7px;
        width: 10px;
        height: 10px;
        margin: 0px auto;
        transform: none;
        position: static;
    }

    &.cart {
        margin: 0px auto;
        transform: none;
        position: static;
    }
}

@keyframes load5 {
    /*
    0%, 100% {
        box-shadow: 0em -2.6em 0em 0em #ffea00, 1.8em -1.8em 0 0em rgba(254, 241, 96, 0.2), 2.5em 0em 0 0em rgba(254, 241, 96, 0.2), 1.75em 1.75em 0 0em rgba(254, 241, 96, 0.2), 0em 2.5em 0 0em rgba(254, 241, 96, 0.2), -1.8em 1.8em 0 0em rgba(254, 241, 96, 0.2), -2.6em 0em 0 0em rgba(254, 241, 96, 0.5), -1.8em -1.8em 0 0em rgba(254, 241, 96, 0.7);
    }
    */

    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(254, 241, 96, 0.7), 1.8em -1.8em 0 0em #ffea00, 2.5em 0em 0 0em rgba(254, 241, 96, 0.2),
            1.75em 1.75em 0 0em rgba(254, 241, 96, 0.2), 0em 2.5em 0 0em rgba(254, 241, 96, 0.2), -1.8em 1.8em 0 0em rgba(254, 241, 96, 0.2),
            -2.6em 0em 0 0em rgba(254, 241, 96, 0.2), -1.8em -1.8em 0 0em rgba(254, 241, 96, 0.5);
    }

    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(254, 241, 96, 0.5), 1.8em -1.8em 0 0em rgba(254, 241, 96, 0.7), 2.5em 0em 0 0em #ffea00,
            1.75em 1.75em 0 0em rgba(254, 241, 96, 0.2), 0em 2.5em 0 0em rgba(254, 241, 96, 0.2), -1.8em 1.8em 0 0em rgba(254, 241, 96, 0.2),
            -2.6em 0em 0 0em rgba(254, 241, 96, 0.2), -1.8em -1.8em 0 0em rgba(254, 241, 96, 0.2);
    }

    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(254, 241, 96, 0.2), 1.8em -1.8em 0 0em rgba(254, 241, 96, 0.5),
            2.5em 0em 0 0em rgba(254, 241, 96, 0.7), 1.75em 1.75em 0 0em #ffea00, 0em 2.5em 0 0em rgba(254, 241, 96, 0.2),
            -1.8em 1.8em 0 0em rgba(254, 241, 96, 0.2), -2.6em 0em 0 0em rgba(254, 241, 96, 0.2),
            -1.8em -1.8em 0 0em rgba(254, 241, 96, 0.2);
    }

    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(254, 241, 96, 0.2), 1.8em -1.8em 0 0em rgba(254, 241, 96, 0.2),
            2.5em 0em 0 0em rgba(254, 241, 96, 0.5), 1.75em 1.75em 0 0em rgba(254, 241, 96, 0.7), 0em 2.5em 0 0em #ffea00,
            -1.8em 1.8em 0 0em rgba(254, 241, 96, 0.2), -2.6em 0em 0 0em rgba(254, 241, 96, 0.2),
            -1.8em -1.8em 0 0em rgba(254, 241, 96, 0.2);
    }

    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(254, 241, 96, 0.2), 1.8em -1.8em 0 0em rgba(254, 241, 96, 0.2),
            2.5em 0em 0 0em rgba(254, 241, 96, 0.2), 1.75em 1.75em 0 0em rgba(254, 241, 96, 0.5), 0em 2.5em 0 0em rgba(254, 241, 96, 0.7),
            -1.8em 1.8em 0 0em #ffea00, -2.6em 0em 0 0em rgba(254, 241, 96, 0.2), -1.8em -1.8em 0 0em rgba(254, 241, 96, 0.2);
    }

    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(254, 241, 96, 0.2), 1.8em -1.8em 0 0em rgba(254, 241, 96, 0.2),
            2.5em 0em 0 0em rgba(254, 241, 96, 0.2), 1.75em 1.75em 0 0em rgba(254, 241, 96, 0.2), 0em 2.5em 0 0em rgba(254, 241, 96, 0.5),
            -1.8em 1.8em 0 0em rgba(254, 241, 96, 0.7), -2.6em 0em 0 0em #ffea00, -1.8em -1.8em 0 0em rgba(254, 241, 96, 0.2);
    }

    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(254, 241, 96, 0.2), 1.8em -1.8em 0 0em rgba(254, 241, 96, 0.2),
            2.5em 0em 0 0em rgba(254, 241, 96, 0.2), 1.75em 1.75em 0 0em rgba(254, 241, 96, 0.2), 0em 2.5em 0 0em rgba(254, 241, 96, 0.2),
            -1.8em 1.8em 0 0em rgba(254, 241, 96, 0.5), -2.6em 0em 0 0em rgba(254, 241, 96, 0.7), -1.8em -1.8em 0 0em #ffea00;
    }
}
