.rowsContainer {
    > :nth-child(even) {
        background-color: #f1f1f1;
    }

    .attachmentRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 5px;

        &:hover {
            background-color: lightgray;
        }

        .buttonsContainer {
            display: flex;
            gap: 10px;
            align-items: center;

            * {
                margin-bottom: 0 !important;
            }
        }
    }
}
