.dateInfoContainer {
    position: relative;
}

.dateInfoContent {
    display: none;
}

.dateInfoContainer:hover .dateInfoContent {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(25px);
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    z-index: 1;
}
