.vselect {
    color: transparent;

    option:not(:checked) {
        color: black;
    }

    option:checked {
        color: black;
    }
}

.selectElementTextContainer {
    position: absolute;
    top: 6px;
    padding-left: 12px;
    display: flex;
    width: 100%;
    pointer-events: none;

    > *:first-child {
        overflow: hidden;
        white-space: nowrap;
        background-image: linear-gradient(to right, black 90%, transparent);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        flex: 1;
    }

    > *:last-child {
        background-color: transparent;
        width: 30px;
    }
}
