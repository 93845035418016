.container {
    padding: 0 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;

    .content {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-wrap: break-word;

        .closeButton {
            position: absolute;
            top: 0;
            right: 0;
            background-color: red;
            border: none;
        }

        .titleContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 22px;
            flex: 0 0 55px;
            font-weight: 550;
            border-bottom: 1px lightgray solid;
            gap: 20px;

            .title {
                flex: 1;
                display: flex;
                justify-content: space-between;

                .advanced {
                    $buttonColor: #337ab7;
                    color: $buttonColor;
                    border: 2px solid $buttonColor;
                    background-color: transparent;
                    border-radius: 4px;
                    font-size: 16px;
                    text-align: center;
                    font-weight: 550;
                }
            }

            .addButton {
                padding: 10px 0;
                border: none;
                width: 45px;
                height: 35px;
                margin: 0;
                padding: 0;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;

                .circle {
                    position: relative;
                    width: 20px;
                    height: 20px;

                    .vertical {
                        position: absolute;
                        left: 50%;
                        top: 0;
                        transform: translateX(-50%);
                        width: 3px;
                        background-color: black;
                        height: 100%;
                    }

                    .horizontal {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        height: 3px;
                        background-color: black;
                        width: 100%;
                    }
                }
            }
        }

        .main {
            padding: 10px 0;
            flex: 1;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .filterRowContainer {
                display: flex;
                flex-direction: column;
                border: 1px solid lightgray;
                gap: 10px;
                padding: 10px;
                border-radius: 5px;
                position: relative;

                .removeButton {
                    padding-right: 10px;
                    padding-top: 5px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    border: none;
                    background-color: transparent;
                }

                .propertyRow {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .valueRow {
                        display: flex;
                        gap: 10px;

                        :last-child {
                            min-width: 10px;
                            flex: 1;
                        }
                    }
                }
            }
        }

        .bottomButtonsContainer {
            display: flex;
            flex: 0 0 70px;
            width: 100%;
            border-top: 1px solid lightgray;
            gap: 10px;
            align-items: center;

            > * {
                height: 40px;
                flex: 3;
            }

            :first-child {
                flex: 1;
            }
        }
    }
}
