$widthHeight: 24px;

%checkboxStyle {
    max-height: 100%;
    height: $widthHeight;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); // Adjust for centering
    width: $widthHeight;
    border-radius: 4px;
    border: 2px solid;
}

.container {
    position: relative;

    > input {
        opacity: 0;
        @extend %checkboxStyle;
    }

    > label {
        cursor: pointer;
        display: block;
        padding-left: calc(#{$widthHeight} + 10px);
        font-size: 14px;
        line-height: $widthHeight; // Center vertically
    }

    input + label {
        &::before {
            content: "";
            @extend %checkboxStyle;
        }

        /* Checkmark */
        &::after {
            content: "";
            border: 2px solid white;
            border-left: 0;
            border-top: 0;
            height: calc(#{$widthHeight} * 0.5);
            left: calc(#{$widthHeight} * 0.35);
            opacity: 0;
            position: absolute;
            top: calc(#{$widthHeight} * 0.175);
            transform: rotate(45deg);
            width: calc(#{$widthHeight} * 0.3);
            // transition: opacity 0.1s ease-in-out;
        }
    }

    input:checked + label {
        &::after {
            opacity: 1;
        }

        &::before {
            border: 1px solid green;
            background-color: green;
        }
    }
}
