﻿/*___________BOOTSTRAP OVERRIDES________________*/

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1536px,
);
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1456px,
);
$font-size-root: 14px;
$blue: #337ab7;
$red: #dc3545;
/*_____________COLORS_____________*/

/*_____________EFFECT STYLES_____________*/

/*_____________SIZES_____________*/
$xs-max-width: 575.98px;
$sm-min-width: 576px;
$sm-max-width: 767.98px;
$md-min-width: 768px; //md includes all printed content
$md-max-width: 991.98px;
$lg-min-width: 992px;
$lg-max-width: 1199.98px;
$xl-min-width: 1200px;
$xl-max-width: 1535.98px;
$xxl-min-width: 1536px;

//adjusts border radius on buttons, cards, tabs, etc
$border-radius: 0.4rem; //default is .25rem
$border-radius-sm: 0.3rem; //default is .2rem
$border-radius-lg: 0.5rem; //default is .3rem

// see below link for more ideas
// https://css-tricks.com/approaches-media-queries-sass/

// this are simple queries that are size-or-larger queries
// printed content always renders at md size
@mixin media-xs {
    @content;
}

@mixin media-sm {
    @media print, (min-width: $sm-min-width) {
        @content;
    }
}

@mixin media-md {
    @media print, (min-width: $md-min-width) {
        @content;
    }
}

@mixin media-lg {
    @media screen and (min-width: $lg-min-width) {
        @content;
    }
}

@mixin media-xl {
    @media screen and (min-width: $xl-min-width) {
        @content;
    }
}

@mixin media-xxl {
    @media screen and (min-width: $xxl-min-width) {
        @content;
    }
}

@mixin media-not-sm {
    @media screen and (max-width: $xs-max-width) {
        @content;
    }
}

@mixin media-not-md {
    @media screen and (max-width: $sm-max-width) {
        @content;
    }
}

@mixin media-not-lg {
    @media print, (max-width: $md-max-width) {
        @content;
    }
}

@mixin media-not-xl {
    @media print, (max-width: $lg-max-width) {
        @content;
    }
}

@mixin media-not-xxl {
    @media print, (max-width: $xl-max-width) {
        @content;
    }
}
