.container {
    width: 100%;
    table-layout: fixed;

    &.activeTable {
        > tbody {
            > tr {
                border-bottom: 1px dashed #8f9298;
                font-weight: bold;

                &:last-child {
                    border-bottom: 1px solid #8f9298;
                }
            }
        }
    }

    > thead {
        background-color: #edf0f4;
        border-bottom: 1px solid #8f9298;

        > tr {
            height: 30px;
        }
    }

    > tbody {
        > tr {
            height: 40px;
            border-bottom: 1px solid #8f9298;
            font-size: 16px;
        }
    }

    > thead,
    tbody {
        > tr {
            > td {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                &:first-child {
                    padding-left: 10px;
                }

                &:last-child {
                    padding-right: 10px;
                }
                /*two td elements*/
                &:first-child:nth-last-child(2),
                &:first-child:nth-last-child(2) ~ td {
                    &:nth-child(1) {
                        width: 50%;
                    }

                    &:nth-child(2) {
                        width: 50%;
                        text-align: right;
                    }
                }
                /*three td elements*/
                &:first-child:nth-last-child(3),
                &:first-child:nth-last-child(3) ~ td {
                    &:nth-child(1) {
                        width: 95px;
                    }

                    &:nth-child(3) {
                        text-align: right;
                        width: 75px;
                    }
                }
                /*four td elements*/
                &:first-child:nth-last-child(4),
                &:first-child:nth-last-child(4) ~ td {
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        width: 25%;
                    }
                }
            }
        }
    }

    &.ignoreSpacing {
        > thead,
        tbody {
            > tr {
                > td {
                    &:first-child:nth-last-child(3),
                    &:first-child:nth-last-child(3) ~ td {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3) {
                            width: 33.33%;
                        }

                        &:nth-child(2) {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}
