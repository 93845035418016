.container {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.cardStyle {
    padding: 20px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

$userScoresReportCardGap: 25px;

.userScoresReportCard {
    display: flex;
    gap: $userScoresReportCardGap;
    flex-wrap: wrap;
    overflow: hidden;

    > * {
        position: relative;
        flex: 1;
        min-width: fit-content;

        //vertical divider
        &:not(:first-child) {
            &::before {
                content: "";
                position: absolute;
                height: 100%;
                width: 1px;
                background-color: lightgray;
                top: 0;
                left: 0;
                box-sizing: border-box;
                margin-left: calc(-#{$userScoresReportCardGap} / 2);
            }
        }

        //horizontal divider
        &::after {
            content: "";
            position: absolute;
            width: 100vw;
            height: 1px;
            background-color: lightgray;
            bottom: calc(-#{$userScoresReportCardGap} / 2);
            left: 0;
            box-sizing: border-box;
        }
    }
}

.userScoresTable {
    th:not(:last-child) {
        padding-right: 20px;
    }

    td:not(:last-child) {
        padding: 5px 20px 5px 0;
    }

    tbody {
        tr {
            &:last-child {
                td {
                    padding-bottom: 0;
                }
            }
        }
    }

    &.notModal {
        th:not(:last-child) {
            cursor: pointer;
        }

        td:last-child {
            &:hover {
                cursor: pointer;
            }

            button {
                font-size: 20px;
                border: none;
                background-color: transparent;
                margin: 0;
                padding: 0;
            }
        }
    }
}
